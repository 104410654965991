.doc .tabset {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  padding: var(--base-space);
  border-radius: 8px;
  border: 1px solid var(--color-brand-gray8);
  margin-top: var(--base-extra-large-space);
}

.doc .tabs.ulist {
  margin-top: 0;
  padding-left: 0;
}

.doc .tabs ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -0.25rem 0 0;
  padding: 0;
}

.doc .tabs li {
  align-items: center;
  /* border: 1px solid var(--color-brand-white);
  border-top: 3px solid var(--color-brand-white); */
  border-bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: var(--weight-semibold);
  height: 2.5rem;
  line-height: 1;
  padding: 0;
  position: relative;
  margin-right: var(--base-large-space);
}

.doc .tabs li + li {
  margin-top: 0;
}

.doc .tabs li a {
  font-family: "Source Sans Pro", sans-serif;
  color: var(--color-brand-primary);
  font-weight: var(--weight-semibold);
}

.doc .tabs li:last-child {
  margin-right: 0;
}

/*
/* .doc .tabs li.is-active {
  color: var(--color-active-tabset);
  border-radius: 3px 3px 0 0;
  border-color: var(--color-brand-gray6);
  border-top: 3px solid var(--color-active-tabset);
} */

.doc .tabset.is-loading .tabs li:first-child::after,
.doc .tabs li.is-active::after {
  background-color: var(--color-brand-white);
  content: "";
  display: block;
  height: 3px;
  /* Chrome doesn't always paint the line accurately, so add a little extra */
  position: absolute;
  bottom: -1.5px;
  left: 0;
  right: 0;
}

.doc .tabs li.is-active::before,
.doc .tabs li:hover::before {
  background-color: var(--color-active-tabset);
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  margin: auto;
}

.doc .tabset > .content {
  /* border: 1px solid var(--color-brand-gray6); */
  /* padding-top: 0.75rem; */
  padding-top: 1.2rem;
  width: 100%;
  /* border-radius: 3px; */
}

.doc .tabset.is-loading .tab-pane:not(:first-child),
.doc .tabset:not(.is-loading) .tab-pane:not(.is-active) {
  display: none;
}

.doc .tab-pane > :first-child {
  margin-top: 0;
}

/* Dropdown button functionaltiy css */

.tabset .dropddown-btn {
  display: none;
}

.doc .tabs ul.hide {
  display: none;
}

.tabset {
  position: relative;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

/* .active-tab-item-row {
  display: inline-block;
  max-width: 60%;
} */

/* .active-tab-item-row a {
  align-items: center;
  color: var(--color-brand-gray1);
  border: 1px solid var(--color-border);
  border-bottom: 0;
  cursor: pointer;
  display: flex;
  font-weight: var(--weight-bold);
  min-height: 2.5rem;
  line-height: 1;
  padding: 0 1.5rem 5px;
  position: relative;
  text-decoration: none;
}
.active-tab-item-row a::after {
  background-color: var(--color-brand-white);
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  bottom: -1.5px;
  left: 0;
  right: 0;
} */
.ulist.tabs {
  margin: 0;
  width: 100%;
  display: flex;
}

.other-tab-box {
  position: relative;
  margin-left: var(--base-large-space);
}

.doc .tabs .dropddown-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--color-brand-blue-secondary);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: var(--weight-normal);
  /* border-bottom: 1px solid var(--color-border); */
}

.doc .tabs .dropddown-btn .fas {
  font-size: 18px;
  margin-left: 10px;
}

.doc .tabs .dropddown-btn:focus,
.doc .tabs .dropddown-btn:hover {
  outline: none;
  text-decoration: none;
}

.doc .tabs ul.other-tablist {
  box-shadow: 0 3px 10px #0000000f;
  border: 1px solid var(--color-brand-gray8);
  margin: 0;
  position: absolute;
  left: 0;
  top: 100%;
  width: 120px;
  display: none;
  overflow-y: auto;
  background-color: var(--color-brand-white);
}

.doc .tabs ul.other-tablist p {
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .doc .tabset:not(.is-loading) .tabs li:not(.is-active) {
  background: var(--color-brand-white);
  min-height: 2.5rem;
  height: auto;
  padding: 0.75rem;
}
.doc .tabs li.is-active {
  display: none;
} */

.doc .tabs ul.other-tablist li {
  width: 100%;
  flex-wrap: wrap;
  margin-right: var(--base-space);
  border-top: 0;
  /* border-bottom: 1px solid var(--color-border); */
}

.doc .tabs ul.other-tablist li.is-active p,
.doc .tabs ul.other-tablist li p:hover {
  background-color: var(--color-brand-gray7);
}

.doc .tabs ul.other-tablist li.is-active::before,
.doc .tabs ul.other-tablist li:hover::before {
  content: none;
}

.doc .tabs ul.other-tablist.show {
  display: block;
}

.other-tab-box.desktop-hide {
  display: none;
}
