.tippy-tooltip.light-theme {
  color: #fff;
  box-shadow:
    /* 0 0 20px 4px rgba(154, 161, 177, 0.15),
  0 4px 80px -8px rgba(36, 40, 47, 0.25),
  0 4px 4px -2px rgba(91, 94, 105, 0.15); */
    0 0 20px 4px rgba(171, 183, 183, 1),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: #ed6924;
}

.tippy-tooltip.light-theme[x-placement^=top] .tippy-arrow {
  border-top: 8px solid #ed6924;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.tippy-tooltip.light-theme[x-placement^=bottom] .tippy-arrow {
  border-bottom: 8px solid #ed6924;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.tippy-tooltip.light-theme[x-placement^=left] .tippy-arrow {
  border-left: 8px solid #ed6924;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.tippy-tooltip.light-theme[x-placement^=right] .tippy-arrow {
  border-right: 8px solid #ed6924;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.tippy-tooltip.light-theme .tippy-backdrop {
  background-color: #ed6924;
}

.tippy-tooltip.light-theme .tippy-roundarrow {
  fill: #ed6924;
}

.tippy-tooltip.light-theme[data-animatefill] {
  background-color: initial;
}
