.zoomhighlight {
  background-color: #ff9;
  border: none;
  border-radius: 3px;
  padding: 0 3px 2px 3px;
}

.summary {
  font-size: 80%;
  font-style: italic;
  margin-top: -1em;
}

.suggestion { font-size: 100%; }

.results { font-size: 100%; }

.category { color: #999; }

.sorting { text-align: right; }

.result_title { font-size: 100%; }

.description {
  color: #008000;
  font-size: 100%;
  margin: 0.5em 0;
}

.context { font-size: 100%; }

.infoline {
  font-size: 80%;
  color: #808080;
  font-style: normal;
}

.zoom_searchform { font-size: 100%; }

.zoom_results_per_page {
  font-size: 80%;
  margin-left: 10px;
}

.zoom_match {
  font-size: 80%;
  margin-left: 10px;
}

.zoom_categories { font-size: 80%; }

.zoom_categories ul {
  display: inline;
  margin: 0;
  padding: 0;
}

.zoom_categories li {
  display: inline;
  list-style-type: none;
  margin-left: 15px;
}

.cat_summary ul {
  display: inline;
  margin: 0;
  padding: 0;
}

.cat_summary li {
  display: inline;
  list-style-type: none;
  margin-left: 15px;
}

.result_image {
  display: block;
  float: left;
}

.result_image img {
  border: 0;
  margin: 10px;
  width: 80px;
}

.result_block {
  clear: left;
  margin-bottom: 1.5em;
  margin-top: 15px;
}

.result_altblock {
  clear: left;
  margin-bottom: 15px;
  margin-top: 15px;
}

.result_pages {
  font-size: 100%;
  margin-top: 1.5em;
}

.result_pagescount { font-size: 100%; }

.searchtime { font-size: 80%; }

.recommended {
  background: #dfffbf;
  border-bottom: 1px dotted #808080;
  border-top: 1px dotted #808080;
  margin-bottom: 15px;
  margin-top: 15px;
}

.recommended_heading {
  float: right;
  font-weight: bold;
}

.recommend_block {
  clear: left;
  margin-bottom: 15px;
  margin-top: 15px;
}

.recommend_title { font-size: 100%; }

.recommend_description {
  color: #008000;
  font-size: 100%;
}

.recommend_infoline {
  color: #808080;
  font-size: 80%;
  font-style: normal;
}

.recommend_image {
  display: block;
  float: left;
}

.recommend_image img {
  border: 0;
  margin: 10px;
  width: 80px;
}

.searchbox {
  height: 35px;
  margin: 0 20px 0 0;
  padding: 0;
  /* position: relative; */
  right: 1px;
  top: 20px;
}

.searchbox input {
  height: 35px;
  border: 1px solid #e1e1e1;
}

.searchbox input[type=text] {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  float: left;
  font-size: 1em;
  padding: 0 40px 0 10px;
  width: 300px;
  background-image: url("../../img/search-icon.svg");
  background-position: right top;
  background-repeat: no-repeat;
}

.searchbox input[type=submit] {
  position: relative;
  /* 4711 */
  top: -13px;
  left: -5px;
  background-color: #e1e1e1;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: 60px;
}

.searchheading {
  /* color: #007ac2; */
  color: var(--color-black);
  font-size: 130%;
  font-weight: bold;
  margin-top: 2em;
}

.search-body form { display: none; }

.search-body {
  font-size: 0.95em;
  margin: 10px auto;
  max-width: 1200px;
}  /* Moves the text to the right */

.search-body ul {
  margin-left: 0;
  margin-top: 0;
  padding-left: 20px;
  padding-top: 0;
}

.search-body aside {
  border: 1px solid #e1e1e1;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 0.9em;
}

.search-body p { margin-bottom: 0.5em; }

.result_block .description { margin-left: 20px; }

.result_altblock .description { margin-left: 20px; }

.result_block .infoline { margin-left: 20px; }

.result_altblock .infoline { margin-left: 20px; }
