@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  src: url(../font/gotham-book.woff) format("woff");
}

@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  src: url(../font/gotham-medium.woff) format("woff");
}

@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 300;
  src: url(../font/gotham-light.woff) format("woff");
}

@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 700;
  src: url(../font/gotham-bold.woff) format("woff");
}
