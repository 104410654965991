.page-versions {
  display: none;
  margin-right: 0.7rem;
  position: relative;
  line-height: 1;
}

@media screen and (min-width: 1024px) {
  .page-versions {
    display: flex;
    justify-content: flex-end;
  }
}

.page-versions .version-menu-toggle {
  color: inherit;
  background: url(../img/chevron.svg) no-repeat;
  background-position: right 0.5rem top 50%;
  background-size: auto 0.75em;
  border: none;
  outline: none;
  line-height: inherit;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  position: relative;
  z-index: var(--z-index-page-version-menu);
}

.page-versions .version-menu {
  flex: 1 1 auto;
  display: flex;
  min-width: 100%;
  flex-direction: column;
  align-items: flex-end;
  background: linear-gradient(to bottom, var(--page-version-menu-background) 0%, var(--page-version-menu-background) 100%) no-repeat;
  padding: 1.375rem 1.5rem 0.5rem 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  white-space: nowrap;
}

.page-versions:not(.is-active) .version-menu {
  display: none;
}

.page-versions .version {
  display: block;
  padding-top: 0.5rem;
}

.page-versions .version.is-current {
  display: none;
}

.page-versions .version.is-missing {
  color: var(--page-version-missing-font-color);
  font-style: italic;
  text-decoration: none;
}
