.hljs-folder {
  position: relative;
  vertical-align: baseline;
  display: inline-block;
  height: var(--folder-icon-height);
}

.hljs-folder::before {
  top: 2px;
  content: "";
  width: var(--folder-icon-width);
  height: var(--folder-icon-height);
  /* margin-top: 0px; */
  margin-right: 3px;
  position: relative;
  display: inline-block;
  background-size: var(--folder-icon-height);
  background-repeat: no-repeat;
  background-image: url("../img/treeview/folder-open.svg");
}

.hljs-hiddenfile,
.hljs-file {
  position: relative;
  vertical-align: baseline;
  display: inline-block;
  height: 16px;
}

.hljs-file::before,
.hljs-hiddenfile::before {
  top: 2px;
  content: "";
  width: 16px;
  height: 16px;
  /* margin-top: 0px; */
  margin-right: 3px;
  margin-left: 1px;
  position: relative;
  display: inline-block;
  background-size: 16px;
  background-repeat: no-repeat;
}

.hljs-hiddenfile {
  opacity: 0.6;
}

/* new css */
.hljs-file.asciidoc::before {
  background-image: url("../img/treeview/asciidoc.svg");
}

.hljs-file.certificate::before {
  background-image: url("../img/treeview/certificate.svg");
}

.hljs-file.css::before {
  background-image: url("../img/treeview/css.svg");
}

.hljs-file.editorconfig::before {
  background-image: url("../img/treeview/editorconfig.svg");
}

.hljs-file.eslint::before {
  background-image: url("../img/treeview/eslint.svg");
}

.hljs-file.favicon::before {
  background-image: url("../img/treeview/favicon.svg");
}

.hljs-file.git::before {
  background-image: url("../img/treeview/git.svg");
}

.hljs-file.gitlab::before {
  background-image: url("../img/treeview/gitlab.svg");
}

.hljs-file.handlebars::before {
  background-image: url("../img/treeview/handlebars.svg");
}

.hljs-file.javascript::before {
  background-image: url("../img/treeview/javascript.svg");
}

.hljs-file.nodejs::before {
  background-image: url("../img/treeview/nodejs.svg");
}

.hljs-file.json::before {
  background-image: url("../img/treeview/json.svg");
}

.hljs-file.settings::before {
  background-image: url("../img/treeview/settings.svg");
}

.hljs-file.svg::before {
  background-image: url("../img/treeview/svg.svg");
}

.hljs-file.stylelint::before {
  background-image: url("../img/treeview/stylelint_light.svg");
}

.hljs-file.vagrant::before {
  background-image: url("../img/treeview/vagrant.svg");
}

.hljs-file.xml::before {
  background-image: url("../img/treeview/xml.svg");
}

.hljs-file.yaml::before {
  background-image: url("../img/treeview/yaml.svg");
}

.hljs-file.yarn::before {
  background-image: url("../img/treeview/yarn.svg");
}

.hljs-file.powershell::before {
  background-image: url("../img/treeview/powershell.svg");
}

.hljs-file.markdown::before {
  background-image: url("../img/treeview/markdown.svg");
}

.hljs-file.sql::before {
  background-image: url("../img/treeview/database.svg");
}

.hljs-folder.ansible::before {
  background-image: url("../img/treeview/folder-ansible-open.svg");
}

.hljs-folder.css::before {
  background-image: url("../img/treeview/folder-css-open.svg");
}

.hljs-folder.dist::before {
  background-image: url("../img/treeview/folder-dist-open.svg");
}

.hljs-folder.docs::before {
  background-image: url("../img/treeview/folder-docs-open.svg");
}

.hljs-folder.helper::before {
  background-image: url("../img/treeview/folder-helper-open.svg");
}

.hljs-folder.images::before {
  background-image: url("../img/treeview/folder-images-open.svg");
}

.hljs-folder.javascript::before {
  background-image: url("../img/treeview/folder-javascript-open.svg");
}

.hljs-folder.layout::before {
  background-image: url("../img/treeview/folder-layout-open.svg");
}

.hljs-folder.lib::before {
  background-image: url("../img/treeview/folder-lib-open.svg");
}

.hljs-folder.public::before {
  background-image: url("../img/treeview/folder-public-open.svg");
}

.hljs-folder.src::before {
  background-image: url("../img/treeview/folder-src-open.svg");
}

.hljs-folder.vagrant::before {
  background-image: url("../img/treeview/folder-open.svg");
}

.hljs-folder.views::before {
  background-image: url("../img/treeview/folder-views-open.svg");
}

.hljs-folder.vscode::before {
  background-image: url("../img/treeview/folder-vscode-open.svg");
}

/* old */

.hljs-file.photo::before {
  background-image: url("../img/treeview/image.svg");
}

.hljs-file.plain::before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIiA+DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIxNzkuMiIgd2lkdGg9IjE3OS4yIj4NCgk8Zz4NCgkJPHBhdGggdHJhbnNmb3JtPSJzY2FsZSgwLjEsLTAuMSkgdHJhbnNsYXRlKDAsLTE1MzYpIiBkPSJNMTQ2OCAxMTU2cTI4IC0yOCA0OCAtNzZ0MjAgLTg4di0xMTUycTAgLTQwIC0yOCAtNjh0LTY4IC0yOGgtMTM0NHEtNDAgMCAtNjggMjh0LTI4IDY4djE2MDBxMCA0MCAyOCA2OHQ2OCAyOGg4OTZxNDAgMCA4OCAtMjB0NzYgLTQ4ek0xMDI0IDE0MDB2LTM3NmgzNzZxLTEwIDI5IC0yMiA0MWwtMzEzIDMxM3EtMTIgMTIgLTQxIDIyek0xNDA4IC0xMjh2MTAyNGgtNDE2cS00MCAwIC02OCAyOHQtMjggNjh2NDE2aC03Njh2LTE1MzZoMTI4MHogTTM4NCA3MzZxMCAxNCA5IDIzdDIzIDloNzA0cTE0IDAgMjMgLTl0OSAtMjN2LTY0cTAgLTE0IC05IC0yM3QtMjMgLTloLTcwNHEtMTQgMCAtMjMgOXQtOSAyM3Y2NHpNMTEyMCA1MTJxMTQgMCAyMyAtOXQ5IC0yM3YtNjRxMCAtMTQgLTkgLTIzdC0yMyAtOWgtNzA0cS0xNCAwIC0yMyA5dC05IDIzdjY0cTAgMTQgOSAyM3QyMyA5aDcwNHpNMTEyMCAyNTZxMTQgMCAyMyAtOXQ5IC0yM3YtNjRxMCAtMTQgLTkgLTIzdC0yMyAtOWgtNzA0IHEtMTQgMCAtMjMgOXQtOSAyM3Y2NHEwIDE0IDkgMjN0MjMgOWg3MDR6IiAvPg0KCTwvZz4NCjwvc3ZnPg==");
}

.hljs-file.source::before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIiA+DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIxNzkuMiIgd2lkdGg9IjE3OS4yIj4NCgk8Zz4NCgkJPHBhdGggdHJhbnNmb3JtPSJzY2FsZSgwLjEsLTAuMSkgdHJhbnNsYXRlKDAsLTE1MzYpIiBkPSJNMTQ2OCAxMTU2cTI4IC0yOCA0OCAtNzZ0MjAgLTg4di0xMTUycTAgLTQwIC0yOCAtNjh0LTY4IC0yOGgtMTM0NHEtNDAgMCAtNjggMjh0LTI4IDY4djE2MDBxMCA0MCAyOCA2OHQ2OCAyOGg4OTZxNDAgMCA4OCAtMjB0NzYgLTQ4ek0xMDI0IDE0MDB2LTM3NmgzNzZxLTEwIDI5IC0yMiA0MWwtMzEzIDMxM3EtMTIgMTIgLTQxIDIyek0xNDA4IC0xMjh2MTAyNGgtNDE2cS00MCAwIC02OCAyOHQtMjggNjh2NDE2aC03Njh2LTE1MzZoMTI4MHogTTQ4MCA3NjhxOCAxMSAyMSAxMi41dDI0IC02LjVsNTEgLTM4cTExIC04IDEyLjUgLTIxdC02LjUgLTI0bC0xODIgLTI0M2wxODIgLTI0M3E4IC0xMSA2LjUgLTI0dC0xMi41IC0yMWwtNTEgLTM4cS0xMSAtOCAtMjQgLTYuNXQtMjEgMTIuNWwtMjI2IDMwMXEtMTQgMTkgMCAzOHpNMTI4MiA0NjdxMTQgLTE5IDAgLTM4bC0yMjYgLTMwMXEtOCAtMTEgLTIxIC0xMi41dC0yNCA2LjVsLTUxIDM4cS0xMSA4IC0xMi41IDIxdDYuNSAyNGwxODIgMjQzIGwtMTgyIDI0M3EtOCAxMSAtNi41IDI0dDEyLjUgMjFsNTEgMzhxMTEgOCAyNCA2LjV0MjEgLTEyLjV6TTY2MiA2cS0xMyAyIC0yMC41IDEzdC01LjUgMjRsMTM4IDgzMXEyIDEzIDEzIDIwLjV0MjQgNS41bDYzIC0xMHExMyAtMiAyMC41IC0xM3Q1LjUgLTI0bC0xMzggLTgzMXEtMiAtMTMgLTEzIC0yMC41dC0yNCAtNS41eiIgLz4NCgk8L2c+DQo8L3N2Zz4=");
}

.hljs-file.archive::before {
  background-image: url("../img/treeview/zip.svg");
}

.hljs-file.audio::before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIiA+DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIxNzkuMiIgd2lkdGg9IjE3OS4yIj4NCgk8Zz4NCgkJPHBhdGggdHJhbnNmb3JtPSJzY2FsZSgwLjEsLTAuMSkgdHJhbnNsYXRlKDAsLTE1MzYpIiBkPSJNMTQ2OCAxMTU2cTI4IC0yOCA0OCAtNzZ0MjAgLTg4di0xMTUycTAgLTQwIC0yOCAtNjh0LTY4IC0yOGgtMTM0NHEtNDAgMCAtNjggMjh0LTI4IDY4djE2MDBxMCA0MCAyOCA2OHQ2OCAyOGg4OTZxNDAgMCA4OCAtMjB0NzYgLTQ4ek0xMDI0IDE0MDB2LTM3NmgzNzZxLTEwIDI5IC0yMiA0MWwtMzEzIDMxM3EtMTIgMTIgLTQxIDIyek0xNDA4IC0xMjh2MTAyNGgtNDE2cS00MCAwIC02OCAyOHQtMjggNjh2NDE2aC03Njh2LTE1MzZoMTI4MHogTTYyMCA2ODZxMjAgLTggMjAgLTMwdi01NDRxMCAtMjIgLTIwIC0zMHEtOCAtMiAtMTIgLTJxLTEyIDAgLTIzIDlsLTE2NiAxNjdoLTEzMXEtMTQgMCAtMjMgOXQtOSAyM3YxOTJxMCAxNCA5IDIzdDIzIDloMTMxbDE2NiAxNjdxMTYgMTUgMzUgN3pNMTAzNyAtM3EzMSAwIDUwIDI0cTEyOSAxNTkgMTI5IDM2M3QtMTI5IDM2M3EtMTYgMjEgLTQzIDI0dC00NyAtMTRxLTIxIC0xNyAtMjMuNSAtNDMuNXQxNC41IC00Ny41IHExMDAgLTEyMyAxMDAgLTI4MnQtMTAwIC0yODJxLTE3IC0yMSAtMTQuNSAtNDcuNXQyMy41IC00Mi41cTE4IC0xNSA0MCAtMTV6TTgyNiAxNDVxMjcgMCA0NyAyMHE4NyA5MyA4NyAyMTl0LTg3IDIxOXEtMTggMTkgLTQ1IDIwdC00NiAtMTd0LTIwIC00NC41dDE4IC00Ni41cTUyIC01NyA1MiAtMTMxdC01MiAtMTMxcS0xOSAtMjAgLTE4IC00Ni41dDIwIC00NC41cTIwIC0xNyA0NCAtMTd6IiAvPg0KCTwvZz4NCjwvc3ZnPg==");
}

.hljs-file.video::before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIiA+DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIxNzkuMiIgd2lkdGg9IjE3OS4yIj4NCgk8Zz4NCgkJPHBhdGggdHJhbnNmb3JtPSJzY2FsZSgwLjEsLTAuMSkgdHJhbnNsYXRlKDAsLTE1MzYpIiBkPSJNMTQ2OCAxMTU2cTI4IC0yOCA0OCAtNzZ0MjAgLTg4di0xMTUycTAgLTQwIC0yOCAtNjh0LTY4IC0yOGgtMTM0NHEtNDAgMCAtNjggMjh0LTI4IDY4djE2MDBxMCA0MCAyOCA2OHQ2OCAyOGg4OTZxNDAgMCA4OCAtMjB0NzYgLTQ4ek0xMDI0IDE0MDB2LTM3NmgzNzZxLTEwIDI5IC0yMiA0MWwtMzEzIDMxM3EtMTIgMTIgLTQxIDIyek0xNDA4IC0xMjh2MTAyNGgtNDE2cS00MCAwIC02OCAyOHQtMjggNjh2NDE2aC03Njh2LTE1MzZoMTI4MHogTTc2OCA3NjhxNTIgMCA5MCAtMzh0MzggLTkwdi0zODRxMCAtNTIgLTM4IC05MHQtOTAgLTM4aC0zODRxLTUyIDAgLTkwIDM4dC0zOCA5MHYzODRxMCA1MiAzOCA5MHQ5MCAzOGgzODR6TTEyNjAgNzY2cTIwIC04IDIwIC0zMHYtNTc2cTAgLTIyIC0yMCAtMzBxLTggLTIgLTEyIC0ycS0xNCAwIC0yMyA5bC0yNjUgMjY2djkwbDI2NSAyNjZxOSA5IDIzIDlxNCAwIDEyIC0yeiIgLz4NCgk8L2c+DQo8L3N2Zz4=");
}

.hljs-file.pdf::before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIiA+DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIxNzkuMiIgd2lkdGg9IjE3OS4yIj4NCgk8Zz4NCgkJPHBhdGggdHJhbnNmb3JtPSJzY2FsZSgwLjEsLTAuMSkgdHJhbnNsYXRlKDAsLTE1MzYpIiBkPSJNMTQ2OCAxMTU2cTI4IC0yOCA0OCAtNzZ0MjAgLTg4di0xMTUycTAgLTQwIC0yOCAtNjh0LTY4IC0yOGgtMTM0NHEtNDAgMCAtNjggMjh0LTI4IDY4djE2MDBxMCA0MCAyOCA2OHQ2OCAyOGg4OTZxNDAgMCA4OCAtMjB0NzYgLTQ4ek0xMDI0IDE0MDB2LTM3NmgzNzZxLTEwIDI5IC0yMiA0MWwtMzEzIDMxM3EtMTIgMTIgLTQxIDIyek0xNDA4IC0xMjh2MTAyNGgtNDE2cS00MCAwIC02OCAyOHQtMjggNjh2NDE2aC03Njh2LTE1MzZoMTI4MHogTTg5NCA0NjVxMzMgLTI2IDg0IC01NnE1OSA3IDExNyA3cTE0NyAwIDE3NyAtNDlxMTYgLTIyIDIgLTUycTAgLTEgLTEgLTJsLTIgLTJ2LTFxLTYgLTM4IC03MSAtMzhxLTQ4IDAgLTExNSAyMHQtMTMwIDUzcS0yMjEgLTI0IC0zOTIgLTgzcS0xNTMgLTI2MiAtMjQyIC0yNjJxLTE1IDAgLTI4IDdsLTI0IDEycS0xIDEgLTYgNXEtMTAgMTAgLTYgMzZxOSA0MCA1NiA5MS41dDEzMiA5Ni41cTE0IDkgMjMgLTZxMiAtMiAyIC00cTUyIDg1IDEwNyAxOTcgcTY4IDEzNiAxMDQgMjYycS0yNCA4MiAtMzAuNSAxNTkuNXQ2LjUgMTI3LjVxMTEgNDAgNDIgNDBoMjFoMXEyMyAwIDM1IC0xNXExOCAtMjEgOSAtNjhxLTIgLTYgLTQgLThxMSAtMyAxIC04di0zMHEtMiAtMTIzIC0xNCAtMTkycTU1IC0xNjQgMTQ2IC0yMzh6TTMxOCA1NHE1MiAyNCAxMzcgMTU4cS01MSAtNDAgLTg3LjUgLTg0dC00OS41IC03NHpNNzE2IDk3NHEtMTUgLTQyIC0yIC0xMzJxMSA3IDcgNDRxMCAzIDcgNDNxMSA0IDQgOCBxLTEgMSAtMSAydC0wLjUgMS41dC0wLjUgMS41cS0xIDIyIC0xMyAzNnEwIC0xIC0xIC0ydi0yek01OTIgMzEzcTEzNSA1NCAyODQgODFxLTIgMSAtMTMgOS41dC0xNiAxMy41cS03NiA2NyAtMTI3IDE3NnEtMjcgLTg2IC04MyAtMTk3cS0zMCAtNTYgLTQ1IC04M3pNMTIzOCAzMjlxLTI0IDI0IC0xNDAgMjRxNzYgLTI4IDEyNCAtMjhxMTQgMCAxOCAxcTAgMSAtMiAzeiIgLz4NCgk8L2c+DQo8L3N2Zz4=");
}

.hljs-file.xls::before {
  background-image: url("../img/treeview/table.svg");
}

.hljs-file.doc::before {
  background-image: url("../img/treeview/word.svg");
}

.hljs-file.ppt::before {
  background-image: url("../img/treeview/powerpoint.svg");
}

.hljs-hiddenfile::before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIiA+DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIxNzkuMiIgd2lkdGg9IjE3OS4yIj4NCgk8Zz4NCgkJPHBhdGggdHJhbnNmb3JtPSJzY2FsZSgwLjEsLTAuMSkgdHJhbnNsYXRlKDAsLTE1MzYpIiBkPSJNMTQ2OCAxMTU2cTI4IC0yOCA0OCAtNzZ0MjAgLTg4di0xMTUycTAgLTQwIC0yOCAtNjh0LTY4IC0yOGgtMTM0NHEtNDAgMCAtNjggMjh0LTI4IDY4djE2MDBxMCA0MCAyOCA2OHQ2OCAyOGg4OTZxNDAgMCA4OCAtMjB0NzYgLTQ4ek0xMDI0IDE0MDB2LTM3NmgzNzZxLTEwIDI5IC0yMiA0MWwtMzEzIDMxM3EtMTIgMTIgLTQxIDIyek0xNDA4IC0xMjh2MTAyNGgtNDE2cS00MCAwIC02OCAyOHQtMjggNjh2NDE2aC03Njh2LTE1MzZoMTI4MHogIiAvPg0KCTwvZz4NCjwvc3ZnPg==");
}

.hljs-tvline {
  margin-left: 6px;
  position: absolute;
  text-indent: -99em;
  padding-bottom: 8px;
  vertical-align: sub;
  display: inline-block;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-brand-primary-dark);
}

.hljs-folder-branch {
  width: 8px;
  height: 8px;
  margin-top: -1px;
  margin-left: 6px;
  text-indent: -99em;
  position: relative;
  vertical-align: sub;
  display: inline-block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-brand-primary-dark);
}

.hljs-folder-branch.win {
  width: 14px;
  margin-right: 2px;
}

.hljs-folder-last-branch {
  height: 7px;
  width: 7px;
  margin-left: 6px;
  text-indent: -99em;
  position: relative;
  vertical-align: sub;
  display: inline-block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-brand-primary-dark);
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-brand-primary-dark);
}

.hljs-folder-last-branch.win {
  width: 13px;
  margin-right: 2px;
}
