/* footer.footer {
  background-color: var(--footer-background);
  color: var(--footer-font-color);
  font-size: calc(15 / var(--rem-base) * 1rem);
  line-height: var(--footer-line-height);
  padding: 1.5rem;
}

.footer p {
  margin: 0.5rem 0;
}

.footer a {
  color: var(--footer-link-font-color);
}  */

.footer {
  background-color: var(--footer-background);
  color: var(--footer-font-color);
  padding: 2.5rem 0;
  position: relative;
  z-index: var(--z-index-footer);
}

.footer a {
  color: inherit;
  position: relative;
  text-decoration: none;
}

.footer a::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--footer-font-color);
  transform: scale3d(0, 0.9999, 0.9999);
  transition: transform 0.3s;
}

.footer a.icon::after,
.footer a.btn::after {
  content: none;
}

.footer a:focus::after,
.footer a:hover::after {
  transform: scale3d(1, 0.9999, 0.9999);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  max-width: 73.75rem;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.footer-links .col {
  flex-basis: 33.3333%;
  padding-top: 0.625rem;
}

.footer-links .col:nth-child(1) {
  flex-basis: 100%;
  padding: 0 0 1.25rem;
}

.footer-links .col:nth-child(5) {
  flex-basis: 100%;
}

.footer-logo {
  padding: 1rem 0;
  width: 220px;
  /* 4711 width: 120px; */
}

.footer-logo img {
  width: 100%;
  vertical-align: top;
}

.footer-links p.address {
  font-weight: var(--weight-light);
  margin: 0;
  white-space: pre-wrap;
}

.footer-links a.white-btn {
  background-color: var(--footer-font-color);
  color: var(--footer-background);
  margin: 1rem 0;
  display: inline-block;
  /* FIXME this should be max-height 52px */
  padding: 1rem 3.5rem;
  border: 2px solid var(--footer-font-color);
  font-weight: var(--weight-bold);
  font-size: 1.125rem;
  letter-spacing: -0.025em;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  float: left;
}

.footer-links a.white-btn:focus,
.footer-links a.white-btn:hover {
  background-color: var(--footer-background);
  color: var(--footer-font-color);
}

.footer-links a.btn ~ a {
  display: inline-block;
  font-weight: var(--weight-bold);
  float: left;
  clear: left;
}

.footer-links ul {
  font-size: 0.875rem;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.footer-links li {
  padding: 0 1rem 0.625rem 0;
}

.footer-links li .heading {
  /* 4711 color: var(--color-brand-red); */
  color: var(--color-white);
  font-weight: var(--weight-bold);
}

.footer-links li .heading,
.footer-links li a {
  line-height: 1.7;
}

.footer-links li a {
  font-size: 0.8125rem;
}

.footer-links .social-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -4px;
}

.footer-links .social-icons li {
  display: inline-block;
  padding: 0;
}

.footer-links .social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin: 0 0.125rem;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}

.footer-links .social-icons svg {
  fill: var(--footer-font-color);
  width: 35px;
  height: 29px;
  transition: fill 0.3s ease-in-out;
}

.footer-links .social-icons a:focus,
.footer-links .social-icons a:hover {
  background-color: var(--footer-font-color);
}

.footer-links .social-icons a:focus svg,
.footer-links .social-icons a:hover svg {
  fill: var(--footer-background);
}

.footer-terms {
  font-size: 0.875rem;
  line-height: 1;
  border-top: 1px solid var(--footer-font-color);
  padding: 2.125rem 0 0.3125rem;
  max-width: 71.25rem;
  margin: 0 auto;
  text-align: center;
}

.footer-terms span {
  display: block;
}

.footer-terms a {
  font-weight: var(--weight-light);
  display: inline-block;
  margin: 0.625rem 0.3125rem;
}

@media screen and (min-width: 769px) {
  .footer-links .col {
    flex-basis: 22%;
  }

  .footer-links .col:nth-child(1) {
    flex-basis: 34%;
  }

  .footer-links .col:nth-child(5) {
    flex-basis: 100%;
  }

  .footer-links li {
    padding-bottom: 1.5rem;
  }

  .footer-terms span {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .footer-terms a {
    display: inline-block;
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  footer.footer {
    /* 4711 padding: 3.75rem 0 3.125rem; */
    padding: 0.75rem 0 2.125rem;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-links .col {
    flex-basis: 17%;
  }

  .footer-links .col:nth-child(1) {
    flex-basis: 27%;
    padding: 0 1.25rem;
  }

  .footer-links .col:nth-child(2) {
    flex-basis: 16%;
  }

  .footer-links .col:nth-child(5) {
    flex-basis: 23%;
  }

  .footer-links li a {
    font-size: inherit;
  }

  .footer-links .social-icons {
    justify-content: flex-end;
    padding-right: 0.75rem;
  }

  .footer-terms {
    text-align: left;
  }

  .footer-terms span {
    margin-left: 0.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .footer-links .social-icons a {
    width: 52px;
    height: 52px;
  }

  .footer-links .social-icons svg {
    width: 50px;
  }
}
