/* Banner with prerelease and old version */
/* .article-banner a.btn {
  padding: 0.675rem 1rem;
  font-size: var(--font-small);
}

.article-banner p {
  font-size: var(--font-small);
}

.article-banner p .fab {
  font-size: 1.2rem;
} */

.article-banner {
  align-items: center;
  /* background: linear-gradient(to right, var(--color-brand-purple) 0%, var(--color-brand-light-blue) 100%); */
  background: transparent;
  border: 1px solid var(--color-pointsharp-blue);
  color: var(--color-pointsharp-blue);
  display: flex;
  line-height: 1;
  margin: 1.25rem 0 0;
  /* margin: calc(-1 * var(--height-spacer)) calc(-1 * var(--width-container-gutter)) var(--height-spacer); */
  padding: 1rem;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
  font-weight: var(--weight-normal);
  flex-wrap: wrap;
}

.article-banner p {
  font-size: var(--body-font-size);
  margin: 0 1rem 0 0;
  color: var(--color-pointsharp-blue);
}

/* .article-banner span {
  font-size: var(--font-small);
  margin: 0.5rem 1rem 0 0;
  color: var(--color-brand-gray4);
  } */

.article-banner svg {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: var(--heading-h3);
}

.article-banner a.btn {
  color: var(--color-pointsharp-blue);
  background-color: var(--color-pointsharp-gray);
  display: block;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1;
  margin-left: auto;
  padding: 0.875rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  border: 1px solid var(--color-pointsharp-blue);
  font-size: var(--body-font-size);
  font-family: "Open Sans", sans-serif;
}

.article-banner a.btn:focus,
.article-banner a.btn:hover {
  background-color: var(--color-pointsharp-blue);
  color: #fff;
}
