.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-nav-mobile);
}

.btn {
  border-radius: 3px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.125rem;
  padding: 5px 15px;
  border: 1px solid var(--color-brand-secmaker);
  background: var(--color-brand-white);
  color: var(--color-brand-white);
}

@media screen and (min-width: 768px) {
  .btn-primary {
    padding: 8px 25px;
  }
}
