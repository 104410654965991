html.is-clipped--navbar {
  overflow-y: hidden;
}

body {
  padding-top: var(--navbar-height);
}

.navbar {
  background-image: url(../img/header-intranet.svg); /* background: var(--navbar-background); */
  /* 4711 */
  background-repeat: no-repeat;
  background-position: right top;
  background-color: var(--navbar-background);
  color: var(--navbar-font-color);
  font-size: calc(16 / var(--rem-base) * 1rem);
  height: var(--navbar-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-navbar);
}

.navbar a {
  text-decoration: none;
}

.navbar-brand {
  display: flex;
  flex: auto;
  /* 4711 */
  /*  padding-left: 1rem; */
  padding-left: 0.75rem;
}

/*
.navbar-brand {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  height: var(--navbar-height);
}
*/

/* 4711 added to include logo image */
.navbar-brand img {
  /* height: 100%; */
  height: 64.75%;
}

.navbar-brand .navbar-item {
  color: var(--navbar-font-color);
}

.navbar-brand .navbar-item:first-child {
  align-self: center;
  padding: 0;
  font-size: calc(22 / var(--rem-base) * 1rem);
  flex-wrap: wrap;
  line-height: 1;
}

.navbar-brand .navbar-item:first-child a {
  color: inherit;
  word-wrap: normal;
}

.navbar-brand .navbar-item:first-child :not(:last-child) {
  padding-right: 0.375rem;
}

.navbar-brand .navbar-item.search {
  flex: auto;
  justify-content: flex-end;
}

#search-input {
  color: #333;
  font-family: inherit;
  font-size: 0.95rem;
  width: 150px;
  border: 1px solid #dbdbdb;
  border-radius: 0.1em;
  line-height: 1.5;
  padding: 0 0.25em;
}

#search-input:disabled {
  background-color: #dbdbdb;
  /* disable cursor */
  cursor: not-allowed;
  pointer-events: all !important;
}

#search-input:disabled::placeholder {
  color: #4c4c4c;
}

#search-input:focus {
  outline: none;
}

.navbar-burger {
  color: var(--navbar-font-color);
  background: none;
  border: none;
  outline: none;
  line-height: 1;
  height: var(--navbar-height);
  position: relative;
  /* width: var(--navbar-height); */
  width: 2.75rem;
  margin-left: auto;
  padding: 0;
}

.navbar-burger span {
  background: var(--navbar-font-color);
  display: block;
  height: 1px;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  top: 50%;
  width: 15px;
}

.navbar-burger span:nth-child(1) {
  margin-top: -6px;
}

.navbar-burger span:nth-child(2) {
  margin-top: -1px;
}

.navbar-burger span:nth-child(3) {
  margin-top: 4px;
}

.navbar-burger.is-active span:nth-child(1) {
  margin-left: -5px;
  transform: rotate(45deg);
  transform-origin: left top;
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  margin-left: -5px;
  transform: rotate(-45deg);
  transform-origin: left bottom;
}

@media screen and (min-width: 1024px) {
  .navbar-end > .navbar-item,
  .navbar-end .navbar-link {
    color: var(--navbar-font-color);
  }

  .navbar-end > a.navbar-item:hover,
  .navbar-end .navbar-link:hover {
    background: var(--navbar_hover-background);
    color: var(--navbar-font-color);
  }

  .navbar-end .navbar-link::after {
    border-color: var(--navbar-font-color);
  }

  .navbar-item.has-dropdown:hover .navbar-link {
    background: var(--navbar_hover-background);
    color: var(--navbar-font-color);
  }
}

.navbar-item,
.navbar-link {
  color: var(--navbar-menu-font-color);
  display: block;
  line-height: var(--doc-line-height);
  padding: 0.5rem 1rem;
  position: relative;
}

.navbar-item {
  flex: none;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item .icon {
  width: 1.1rem;
  height: 1.1rem;
  display: block;
}

.navbar-link {
  padding-right: 2.5em;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-dropdown .navbar-item.has-label {
  display: flex;
  justify-content: space-between;
}

.navbar-dropdown .navbar-item small {
  color: var(--toolbar-muted-color);
  font-size: calc(12 / var(--rem-base) * 1rem);
}

.navbar-divider {
  background-color: var(--navbar-menu-border-color);
  border: none;
  height: 1px;
  margin: 0.25rem 0;
}

.navbar .button {
  display: inline-flex;
  align-items: center;
  background: var(--navbar-button-background);
  border: 1px solid var(--navbar-button-border-color);
  border-radius: 0.15rem;
  height: 1.75rem;
  color: var(--navbar-button-font-color);
  padding: 0 0.75em;
  white-space: nowrap;
}

@media screen and (max-width: 1023px) {
  .navbar-brand {
    height: inherit;
  }

  .navbar-brand .navbar-item {
    align-items: center;
    display: flex;
  }

  /* .navbar-menu {
    display: none;
  } */

  .navbar-menu {
    background: var(--navbar-menu-background);
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    max-height: var(--body-min-height);
    overflow-y: auto;
    overscroll-behavior: none;
    padding: 0.5rem 0;
  }

  .navbar-menu:not(.is-active) {
    display: none;
  }

  .navbar-menu a.navbar-item:hover,
  .navbar-menu .navbar-link:hover {
    background-color: var(--navbar-menu_hover-background);
  }
}

@media screen and (min-width: 1024px) {
  .navbar,
  .navbar-menu,
  .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar-link::after {
    border-width: 0 0 1px 1px;
    border-style: solid;
    content: " ";
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
  }

  .navbar-menu {
    flex-grow: 1;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    background-color: var(--navbar-menu-background);
    border: 1px solid var(--navbar-menu-border-color);
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
    display: none;
    top: 100%;
    left: 0;
    min-width: 100%;
    position: absolute;
  }

  .navbar-dropdown .navbar-item {
    padding: 0.5rem 1rem;
    white-space: nowrap;
  }

  .navbar-dropdown .navbar-item small {
    position: relative;
    right: -2rem;
  }

  .navbar-dropdown .navbar-item:last-child {
    border-radius: inherit;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-dropdown a.navbar-item:hover {
    background-color: var(--navbar-menu_hover-background);
  }
}
