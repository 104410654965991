/* i.badge {
  position: relative;
  height: 1em;
  font-weight: 400;
  font-style: normal;
  font-size: 0.65em;
  line-height: 1.5;
  vertical-align: middle;
  margin-left: 0.5em;
  margin-right: 1.5em;
}

h1 i.badge {
  line-height: 1.4;
  font-size: 0.5em;
}

i.badge::before {
  content: "B";
  display: block;
  border: 0;
  border-radius: 50%;
  background: #d3d4d5;
  color: #000;
  padding: 0.1em 0.4em;
  position: absolute;
  left: 0;
  top: -0.2em;
  height: 1.5em;
}

i.badge.deprecated::before {
  content: "D";
  background: #ffd4d5;
}

h1 i.badge.deprecated::before,
h2 i.badge.deprecated::before,
h3 i.badge.deprecated::before {
  content: "Deprecated";
  line-height: 1.4;
}

i.badge.preview::before {
  content: "P";
  background: #d3d4ff;
}

h1 i.badge::before,
h2 i.badge::before,
h3 i.badge::before {
  border-radius: 0.5rem;
} */

i.badge {
  position: relative;
  height: 1em;
  font-weight: 400;
  font-style: normal;
  font-size: 0.65em;
  line-height: 1.5;
  vertical-align: middle;
  margin-left: 0.5em;
  margin-right: 1.5em;
  white-space: nowrap;
}

h1 i.badge {
  line-height: 1.4;
  font-size: 0.5em;
}

.crumbs i.badge {
  font-size: 1em;
  line-height: 1;
}

i.badge::before {
  content: "B";
  display: block;
  border: 0;
  border-radius: 50%;
  background: #d3d4d5;
  color: #000;
  padding: 0.1em 0.4em;
  position: absolute;
  left: 0;
  top: -0.2em;
  height: 1.5em;
}

h1 i.badge::before,
h2 i.badge::before,
h3 i.badge::before {
  border-radius: 0.5rem;
  padding-top: 0;
  top: -0.1em;
  line-height: 1.4;
}

i.badge.deprecated::before {
  content: "D";
  background: #ffd4d5;
}

h1 i.badge.deprecated::before,
h2 i.badge.deprecated::before,
h3 i.badge.deprecated::before {
  content: "Deprecated";
}

i.badge.preview::before {
  content: "P";
  background: #d3d4ff;
}

h1 i.badge.preview::before,
h2 i.badge.preview::before,
h3 i.badge.preview::before {
  content: "Preview";
}

i.badge.early::before {
  content: "E";
  background: #d3d4ff;
}

h1 i.badge.early::before,
h2 i.badge.early::before,
h3 i.badge.early::before {
  content: "Early\0000a0Access";
}

i.badge.plan::before {
  content: "$";
  background: #d3ffd4;
}

h1 i.badge.plan::before,
h2 i.badge.plan::before,
h3 i.badge.plan::before {
  content: "Plan Feature";
  white-space: nowrap;
  padding: 0 0.5rem;
}

i.badge.tobedeleted::before {
  content: "\2715"; /* Multiplication sign */
  color: var(--color-white);
  background: var(--color-red);
}

h1 i.badge.tobedeleted::before,
h2 i.badge.tobedeleted::before,
h3 i.badge.tobedeleted::before {
  content: "To\0000a0be\0000a0deleted";
  color: var(--color-white);
}

i.badge.new::before {
  content: "\2714"; /* Check sign */
  color: var(--color-white);
  background: var(--color-brand-secmaker);
}

h1 i.badge.new::before,
h2 i.badge.new::before,
h3 i.badge.new::before {
  content: "New";
  color: var(--color-white);
}

i.badge.review::before {
  content: "R";
  background: var(--color-yellow);
}

h1 i.badge.review::before,
h2 i.badge.review::before,
h3 i.badge.review::before {
  content: "Review";
}

/*
.review::after {
  content: " (review)";
}

.deprecated::after {
  content: " (deprecated)";
}

.new::after {
  content: " (new)";
}

.tobedeleted::after {
  content: " (tobedeleted)";
}

.preview::after {
  content: " (preview)";
} */
