/* 47111 Admonition rules */
.doc .admonitionblock .icon {
  border-radius: 1rem;
}

i.fa[class^='icon-'],
i.fa[class*=' icon-']::before {
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

i.fa.icon-note::before {
  background: no-repeat url("../img/note.svg");
}

i.fa.icon-tip::before {
  background: no-repeat url("../img/tip.svg");
}

i.fa.icon-important::before {
  background: no-repeat url("../img/important.svg");
}

i.fa.icon-warning::before {
  background: no-repeat url("../img/warning.svg");
}

i.fa.icon-caution::before {
  background: no-repeat url("../img/caution.svg");
}

.edit-this-page-email {
  display: none;
  padding-right: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .edit-this-page-email {
    display: block;
  }
}

.toolbar .edit-this-page-email a {
  color: var(--toolbar-muted-color);
  display: inline-block;
  width: 100%;
  height: 100%;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  /* Change edit icon to email icon */
  /* background: url(../img/edit.svg) no-repeat center; */
  background: url(../img/email.svg) no-repeat center;
  /* background-size: 60%; */
  vertical-align: top;
  /* color: transparent; */
}

.admonitionblock.think td.icon .icon-note::before {
  content: "\f0eb";
  color: #f00;
}

/* Tooltip text colour */
.gloss {
  /* color: #348899; */
  color: #222;
  text-decoration: underline;
  font-weight: normal;
}

/* 4711 Inställningarna nedan gör att titeln hamnar i "mitten" samt att den */
.navbar-brand .navbar-item + .navbar-item {
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  /* 4711 */
  font-family: 'Pointsharp', sans-serif;
  font-size: 1.75em;
  font-weight: 700;
  font-style: bold;
  margin-left: 35%;
  margin-right: 65%;
}

.algolia-autocomplete .ds-dropdown-menu [class^=ds-dataset-] {
  max-height: calc(100vh - 3.25rem);
}

.algolia-autocomplete .algolia-docsearch-suggestion--title {
  font-weight: 500;
}

.algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--subcategory-column {
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .navbar-brand .navbar-item + .navbar-item {
    padding-left: 0;
    padding-right: 0;
  }

  .algolia-autocomplete .ds-dropdown-menu {
    min-width: calc(100vw - 2.75rem) !important;
  }
}

@media screen and (min-width: 1024px) {
  .navbar-brand {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }

  .navbar-menu {
    -ms-flex-positive: 0;
    -webkit-box-flex: 0;
    flex-grow: 0;
  }
}

#search-input {
  color: #333;
  font-family: inherit;
  font-size: 0.95rem;
  width: 150px;
  border: 1px solid #dbdbdb;
  border-radius: 0.1em;
  line-height: 1.5;
  padding: 0 0.25em;
}

@media screen and (min-width: 769px) {
  #search-input {
    width: 200px;
  }
}

.doc .image.conum > img {
  height: 0.9em;
  width: auto;
  vertical-align: -0.1em;
}
